import React from "react";
import PG_TOU_INFO_SECTION_CONTENT from "./index.content";

const LOCALIZATION_ENABLED = process.env.LOCALIZATION_SWITCH === "true";

const InfoSection = ({ currentLocale, refWhyNotAvailableBlock }) => {
  return (
    <div
      className="container PgTOU top"
      id="home"
      ref={refWhyNotAvailableBlock}
    >
      <div className="row">
        <div className="col-12 mx-auto terms_of_use">
          <h1>TERMS OF USE</h1>
          <div>{PG_TOU_INFO_SECTION_CONTENT[currentLocale]?.t1()}</div>
          <ol>
            <li>
              <b>Use of Service.</b> Your use of AMZScout’s Service is based on
              the license of AMZScout’s Intellectual Property to you. We grant
              you a limited, revocable, non-transferable license that permits
              you to use AMZScout’s Intellectual Property in accordance with the
              terms of this Agreement for as long as: (i) you remain a Member in
              conformity with the terms of this Agreement and do not breach the
              terms thereof, and (ii) AMZScout exists. Your license will
              terminate in the event that either you breach the terms of this
              Agreement, or AMZScout technically or actually ceases to exist.
              AMZScout reserves all rights to any Intellectual Property not
              expressly granted to you.
              <hr className="PgTOU__hr" />
              <b>Permitted Use.</b> You are not permitted to use the Site and/or
              the Service for anything other than the following purposes: (i)
              viewing the Site without using any tools or software for automatic
              data extraction (bots); (ii) signing up and logging in for the
              Service; (iii) ordering the specific services provided by
              AMZScout, making payment for such services and accessing reports
              and other results of services; (iv) transferring to other websites
              through links provided on the Site; and (v) making use of other
              facilities (tools) that may be provided on the Site/by the
              Service.
              <hr className="PgTOU__hr" />
              The Member/user is expressly prohibited from: (i) using automated
              systems or software to extract data from the Site or from the
              Service’s tools; (ii) sublicensing, renting, leasing,
              transferring, reselling, gifting, or distributing any information
              from the Site/the Service.
              <hr className="PgTOU__hr" />
              In order to prevent misuse of the Service indicated above, there
              are a few limitations in place, such as the maximum number of
              requests allowed within a certain period of time. You will not
              encounter these limitations during your normal use of the Service.
              If you believe you have faced any of these limitations without
              reason, please contact support@amzsc.amzgb.com.
            </li>
            <li>
              <b>Account.</b> If you choose to use our Service, you represent
              that you have full power, capacity and authority to accept these
              Terms.
            </li>
            <li>
              You affirm that you possess legal parental or guardian consent to
              use this Site if you are under eighteen (18) years of age, or you
              are an emancipated minor, or you are an adult with the capacity to
              enter into agreements, and that you will abide by and comply with
              these Terms of Service.
            </li>
            <li>
              You must be a human to open an account. Accounts registered by{" "}
              <i>“bots”</i> or other automated methods are not permitted
            </li>
            <li>
              You must personally and manually create your account without using
              any automated means aside from the auto-complete feature in your
              website browser. A third party may not create an account for you
              and you must not allow any third party to use your information to
              create an account.
            </li>
            <li>
              You must provide your legal full name, valid email address, and
              any other information we request to complete your account-signup
              process.
            </li>
            <li>
              You are responsible for maintaining the security of your account
              and password. We will not be liable for any loss or damage from
              your failure to comply with this security obligation.
            </li>
            <li>
              The purchase, sale, assignment or transfer of any accounts is
              prohibited.
            </li>
            <li>
              You are responsible for your own conduct and activities on,
              through or related to our Service. If you create an account to use
              our Service, you are responsible for all conduct or activities on,
              through or by use of your account. You must immediately notify us
              of any unauthorized use of your account.
            </li>
            <li>
              <b>{PG_TOU_INFO_SECTION_CONTENT[currentLocale]?.t4()}</b>
              <ol>
                <li>
                  We or you may terminate this Agreement at any time by giving
                  notice to the other party. We may suspend our Service to you
                  at any time, with or without cause.
                </li>
                <li>
                  AMZScout may, in its reasonable discretion, freeze or
                  terminate your account in the case of your
                  breaching/infringement of the Terms of Use or applicable laws,
                  or if we are ordered to do so by a court/other authorized
                  body.
                  <hr className="PgTOU__hr" />
                  Any user or the Member that breaches this Agreement (the Terms
                  of Use) is liable to have their subscription terminated and
                  account deleted, at the discretion of AMZScout, without
                  refunding.
                  <hr className="PgTOU__hr" />
                  Also, your access to AMZScout services/subscription may
                  terminate and your account may be deleted under paragraph
                  10(5) of this Terms of Use.
                  <hr className="PgTOU__hr" />
                  In other cases, not listed above in this paragraph 10(2),
                  termination of your subscription/deletion of your account is
                  subject to refunding a prorated portion of any pre-payment
                  plan.
                  <hr className="PgTOU__hr" />
                  If your account is terminated, we may permanently delete your
                  account and all data associated with it from our Site.
                </li>
                <li>
                  If you don’t log in to your account for <i>twelve (12)</i> or
                  more months, we may treat your account as <i>“inactive”</i>{" "}
                  and permanently delete the account and all data associated
                  with it.
                </li>
                <li>
                  If you contact us within <i>ten (10)</i> days of purchasing
                  any AMZScout services to terminate such services, we will
                  refund the amount of your LAST PAYMENT to AMZScout. This
                  provision shall apply to monthly, yearly and/or lifetime
                  service plans.
                  <hr className="PgTOU__hr" />
                  Some of AMZScout's digital products (such as reports or
                  learning materials) are non refundable or may have a different
                  refund period. You will be notified of this before a purchase.
                </li>
                <li>
                  In the event that AMZScout technically or actually ceases to
                  exist, you will be notified at least 2 (two) months before the
                  date of cessation of AMZScout services (Cessation Date). Since
                  Cessation Date your account will be deleted and any monthly,
                  yearly or lifetime subscriptions will not be returned and / or
                  refunded.
                </li>
              </ol>
            </li>
            <li>
              Monthly Service Plan payments are due for any month on the same
              date, or the closest date in that month, to the day that you
              signed up with us and made your first monthly payment. You must
              terminate your Monthly Service Plan at least{" "}
              <i>twenty-four (24)</i> hours prior to the next monthly due date
              in order to avoid being charged for that month. Annual Service
              Plan payments are due for any year on the same date, or the
              closest date to the day that you signed up with us and made your
              first annual payment. You must terminate your Annual Service Plan
              at least <i>twenty-four (24)</i> hours prior to the next annual
              due date in order to avoid being charged for that year.
              <hr className="PgTOU__hr" />
              <b>Lifetime subscription.</b> Lifetime subscription is for the
              lifetime of the AMZScout services. Lifetime (the period of a
              lifetime subscription) shall be defined as for as long as AMZScout
              exists as commercial services. The user cannot transfer his/her
              lifetime subscription to any other entity / person, for any
              reason, in any way or under any circumstances whatsoever. If
              AMZScout ceases to exist, your lifetime subscription will
              terminate on Cessation Date as defined in paragraph 10(5) of this
              Terms of Use.
            </li>
            <li>
              <b>Account Dispute.</b> We consider the person or business entity
              in whose name the account is registered in to be the owner.
            </li>
            <li>
              <b>Pricing.</b> Our charges related to our Services are posted on
              our Site at{" "}
              <a className="PgTOU__link" href="https://amzsc.amzgb.com">
                https://amzsc.amzgb.com
              </a>{" "}
              and may be changed from time to time.
            </li>
            <li>
              Depending on the type of subscription/account, some of AMZScout’s
              services (tools) are limited in terms of the number of launches
              that are indicated when you are to obtain the relevant
              subscription/pay for the relevant service or tool. A “launch”, for
              the purpose of this Section, shall mean that the relevant tool
              from AMZScout (a browser extension) is running on a particular
              Amazon page.
              <ol>
                <li>
                  <b>The Launches for PRO Extension for Chrome.</b> The number
                  of launches is limited, depending on your account’s type. The
                  users who possess the Standard account are allowed to make 600
                  launches of the PRO Extension for Chrome on Amazon pages
                  during each monthly period, starting from the date the
                  subscription begins until the corresponding date of each
                  subsequent calendar month. If you run out of launch
                  opportunities due to this limitation, you will be offered the
                  opportunity to upgrade to an Enterprise account.
                </li>
                <li>
                  <b>Amazon Keyword Search and Reverse ASIN Lookup Tools.</b>{" "}
                  You are limited to 50 launches using both tools collectively
                  each day. This limit applies to combined launches between both
                  tools.
                </li>
                <li>
                  <b>The Launches for Quick View Extension for Chrome.</b> The
                  Quick View Extension for Chrome automatically launches each
                  time a user opens an Amazon page and the Quick View Extension
                  is installed and active.
                  <hr className="PgTOU__hr" />
                  You are limited to 500 pages (launches) during each monthly
                  period, starting from the date of the subscription until the
                  corresponding date of each subsequent calendar month. If the
                  user is interested in obtaining unlimited information, they
                  may consider upgrading to the Amazon Dropshipping, Arbitrage,
                  and Wholesale Extension. The Amazon Dropshipping, Arbitrage,
                  and Wholesale Extension don’t have limitations in terms of the
                  number of launches. This Extension may be obtained{" "}
                  <a
                    className="PgTOU__link"
                    href="https://chrome.google.com/webstore/detail/amazon-dropshipping-arbit/edkgpjhfpfpgkohafpaliolcfnijnibh?utm_source=site&utm_medium=amzscout&utm_campaign=terms"
                  >
                    here.
                  </a>
                </li>
              </ol>
            </li>
            <li>
              <b>Payments.</b> As long as you’re a Monthly Service Plan member
              or have an outstanding balance with us, you'll provide us with a
              valid payment source and authorize us to deduct the monthly
              charges against that payment source. You’ll replace the
              information for any payment source that expires with information
              for a different valid payment source. Anyone using a payment
              source represents and warrants that they are authorized to use
              that payment source, and that any and all charges may be billed to
              that payment source and won’t be rejected. If, for some reason,
              we’re unable to process your payment, we’ll try to contact you by
              email so that you may provide us with an alternate payment source.
              Payment sources include credit cards, PayPal or any other means
              which we deem acceptable. Failure to perform payment shall
              construe as material breach of this Agreement.
            </li>
            <li>
              <b>Mutual Non-Disparagement Covenant.</b> You agree that you will
              not, at any time, make directly or indirectly, any oral or written
              public statements that are disparaging of us, our products and/or
              services, and any of our present or former Team. We (limited to
              our officers and directors) agree that we will not, at any time,
              make, directly or indirectly, any oral or written public
              statements that are disparaging of you. Disparagement shall be
              defined as any oral or written public statements that impugn the
              qualities, character, honesty, integrity, morality, business
              acumen or abilities of the subject individual or entity. The
              Parties acknowledge and agree that it would be difficult or
              impossible to determine with absolute precision the amount of
              damages that would or might be incurred as a result of a party's
              violation of this covenant. The Parties therefore agree that the
              liquidated damages in the amount of Five Thousand U.S. Dollars
              ($5,000.00) per violation provided under this Agreement are in
              lieu of actual damages and are the Parties' reasonable estimate of
              fair compensation for the losses that may reasonably be incurred
              by each violation of this covenant.
            </li>
            <li>
              <b>Changes.</b> We may change the terms of this Agreement or our
              pricing at any time by posting the changes to this Site or
              notifying you by email.
            </li>
            <li>
              <b>Privacy Policy.</b> AMZScout may use and disclose your
              information according to our Privacy Policy. The Terms of Our
              Privacy Policy, including compliance with the EU’s General Data
              Protection Regulation is incorporated into these Terms of Service
              by reference.
            </li>
            <li>
              <b>Member/user warranties.</b> You promise not to:
              <ol>
                <li>
                  Send Unsolicited Bulk Mail (<i>“SPAM”</i>) or other
                  communications;
                </li>
                <li>
                  Upload, post, email, or otherwise transmit any material that
                  contains software viruses and/or any other computer code,
                  files, or programs designed to interrupt, destroy, or limit
                  the functionality of any computer software or hardware or
                  telecommunications equipment;
                </li>
                <li>
                  Send or transmit pornography or other sexually explicit
                  communications, communications offering to sell illegal goods
                  or services, communications that violate CAN-SPAM laws, or
                  marketing or commercial communications without permission;
                </li>
                <li>
                  Infringe on any third party’s ownership rights by using any
                  material in your transmissions or uploads that is not owned or
                  licensed by you. This includes text, photos, graphics, and
                  other content;
                </li>
                <li>
                  Use any misleading or incorrect names, addresses, email
                  addresses, subject lines, photographs, content or other
                  information on the Site or in any communications or
                  transmissions sent using our Service;
                </li>
                <li>
                  Breach or circumvent any laws, third party rights or our
                  systems, policies, or determinations of your account status;
                </li>
                <li>
                  Use any robot, spider, scraper or other automated means to
                  access our Service for any purpose;
                </li>
                <li>
                  Harvest or otherwise collect information about members without
                  their consent; <br /> and
                </li>
                <li>
                  Upload or transmit harassing, offensive, obscene, defamatory,
                  threatening, or malicious content or communications.
                </li>
              </ol>
            </li>
            <li>
              <b>Disclaimer of Warranties.</b> TO THE FULLEST EXTENT PERMITTED
              BY THE APPLICABLE LAW, AMZSCOUT OFFERS THE SITE AND SERVICES{" "}
              <b>AS-IS</b> AND MAKES NO REPRESENTATIONS OR WARRANTIES OF ANY
              KIND CONCERNING THE SITE AND/OR ITS SERVICES, EXPRESS, IMPLIED,
              STATUTORY OR OTHERWISE, INCLUDING, WITHOUT LIMITATION,
              MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE. AMZSCOUT
              DOES NOT WARRANT THAT THE FUNCTIONS OR CONTENT CONTAINED ON THE
              SITE AND/OR SERVICES WILL BE UNINTERRUPTED OR ERROR-FREE, THAT
              DEFECTS WILL BE CORRECTED, OR THAT OUR SERVERS ARE FREE OF VIRUSES
              OR OTHER HARMFUL COMPONENTS.
              <hr className="PgTOU__hr" />
              AMZScout undertakes commercially reasonable efforts to ensure that
              the information it provides is current and accurate. However,
              information provided by AMZScout is an approximate estimation and
              cannot be exact due to reliance on third parties over whom
              AMZScout has no control. AMZScout makes no guarantee or
              representation of any kind concerning the results of your use of
              the Site or other AMZScout Services. Any testimonials or examples
              displayed or depicted through the AMZScout Site, programs,
              software, products and/or other AMZScout services are only
              examples of what may be possible. There can be no assurance as to
              any particular outcome, including increased income, Amazon
              ranking, sales, and/or any other outcome, based on the use of the
              Service or any other products, programs or services offered by
              AMZScout.
            </li>
            <li className="PgTOU-restrictions">
              <b>No legal advice or professional consulting services.</b> The
              information provided through this website, AMZScout's emails, or
              other AMZScout Products does not, and is not intended to,
              constitute legal advice or professional marketing consulting.
              Instead, all information, content, and materials available on this
              website regarding any AMZScout Products are for general
              informational purposes only.
              <hr className="PgTOU__hr" />
              AMZScout Corp. is not a law or professional consulting firm, and
              does not provide legal advice or professional marketing consulting
              services.
            </li>
            <li>
              <b>Results Not Guaranteed.</b> AMZScout makes no guarantee or
              representation of any kind concerning the results of your use of
              the website or Service. Any testimonials or examples displayed or
              depicted through AMZScout’s website, programs, and/or the Service
              are only examples of what may be possible. There can be no
              assurance as to any particular outcome, including increased
              income, Amazon rankings, sales, and/or any other outcome, based on
              the use of the Service or any other products, programs or service
              offered by us.
            </li>
            <li>
              <b>Limitation of Liability.</b> EXCEPT TO THE MINIMUM EXTENT
              REQUIRED BY APPLICABLE LAW AND THEN ONLY TO THAT EXTENT, IN NO
              EVENT WILL AMZSCOUT, ITS EMPLOYEES, OFFICERS, DIRECTORS,
              AFFILIATES OR AGENTS (the “AMZSCOUT TEAM”) BE LIABLE TO YOU ON ANY
              LEGAL THEORY FOR ANY INCIDENTAL, DIRECT, INDIRECT, PUNITIVE,
              ACTUAL, CONSEQUENTIAL, SPECIAL, EXEMPLARY OR OTHER DAMAGES,
              INCLUDING WITHOUT LIMITATION, LOSS OF REVENUE OR INCOME, LOST
              PROFITS, PAIN AND SUFFERING, EMOTIONAL DISTRESS, COST OF
              SUBSTITUTE GOODS OR SERVICES, OR SIMILAR DAMAGES SUFFERED OR
              INCURRED BY YOU OR ANY THIRD PARTY THAT ARISE IN CONNECTION WITH
              THE WEBSITE OR SERVICES (OR THE TERMINATION THEREOF FOR ANY
              REASON), EVEN IF THE AMZSCOUT TEAM HAS BEEN ADVISED OF THE
              POSSIBILITY OF SUCH DAMAGES. OUR TOTAL LIABILITY FOR ALL CLAIMS
              MADE ABOUT THE SERVICE IN ANY MONTH WILL BE NO MORE THAN WHAT YOU
              PAID TO US THE MONTH BEFORE.
            </li>
            <li>
              <b>Indemnification for Breach of Terms of Use.</b> You agree to
              indemnify and hold harmless the AMZScout Team from and against any
              and all loss, expenses, damages, and costs, including without
              limitation reasonable attorneys’ fees, resulting, whether directly
              or indirectly, from your violation of these Terms. You also agree
              to indemnify and hold harmless the AMZScout Team from and against
              any and all claims brought by third parties arising out of the
              your use of the Site and/or Services and the content you make
              available via the Site and/or Services by any means, including
              without limitation through emailing, posting, a link, referencing
              content, or otherwise, whether such actions are taken by you or a
              third party using your password.
            </li>
            <li>
              <b>Attorneys’ Fees.</b> In the event we completely or
              substantially prevail in any action against you arising out of or
              relating to this Agreement, we shall be entitled to recover
              damages, other relief that may be awarded, costs and expenses,
              including reasonable attorneys’ fees, incurred in connection with
              any such action.
            </li>
            <li>
              <b>Subpoena Costs.</b> If we have to provide information in
              response to a subpoena related to your account, then we may charge
              you for our costs. These costs may include attorneys’ fees and
              employee costs for time spent retrieving records, preparing
              documents, and participating in a deposition.
            </li>
            <li>
              <b>Intellectual Property. Trademarks.</b>
              <ol>
                <li>
                  Ours. The AMZScout Site, its original content, features,
                  functionality (including appearance), and Service Content (
                  <i>“Intellectual Property”</i>) are owned by AMZScout and are
                  protected by international copyright, trademark, patent, trade
                  secret, and other intellectual property or proprietary rights
                  laws. You agree not to copy, modify, create derivative works
                  of, publicly display, publicly perform, transfer, sell,
                  sublicense, or republish any of our copyrighted content, and
                  to use this content in conformity with this Agreement (the
                  Terms of Use). The term <i>“content”</i> includes, without
                  limitation, information, data, text, photographs, videos,
                  audio clips, written posts and comments, software, scripts,
                  graphics, adwords, and interactive features generated,
                  provided, or otherwise made accessible on or through AMZScout.
                </li>
                <li>
                  Yours. You represent and warrant that you either own or have
                  permission to use all of the material you upload to or
                  transmit using AMZScout. You retain ownership of the materials
                  you upload to, or transmit using the Service. You grant us a
                  non-exclusive worldwide license to use or disclose your
                  materials subject to our Privacy Policy.
                </li>
                <li>
                  Trademarks, logos, brand names. AMZ SCOUT, AMZSCOUT, logos of
                  AMZScout on this website and all other AMZScout Products are
                  registered trademarks of AMZScout Corp., PA, USA, and may not
                  be used in connection with any product in a manner that is
                  likely to cause confusion, deception, or mistake about the
                  source of these goods and/or services.
                  <hr className="PgTOU__hr" />
                  All other trademarks, product names, logos, brand and company
                  names cited in this website or through any other AMZScout
                  Products (services, tools) are the property of their
                  respective owners and should be used for identification
                  purposes only. Use of these names, trademarks, logos, and
                  brands does not imply endorsement.
                </li>
              </ol>
            </li>
            <li>
              <b>Compliance with Law.</b> You represent and warrant that your
              use and interaction with AMZScout is in compliance with all
              national, federal, state, and local laws, ordinances and
              regulations. If you are located in a Country outside of the United
              States, it is your responsibility to determine that you are in
              compliance with the laws of that Country. You agree to indemnify
              and hold us harmless from any losses, including attorneys’ fees
              that result from your breach of any part of these warranties.
            </li>
            <li className="PgTOU-restrictions" id="why-not-available">
              <b>Understanding AMZScout country restrictions</b>
              <br />
              The following article applies to all AZMScout products and the
              AMZScout Affiliate program.
              <hr className="PgTOU__hr" />
              AMZScout must comply with sanctions imposed by the{" "}
              <a
                className="PgTOU__link"
                href="https://www.treasury.gov/resource-center/sanctions/Pages/default.aspx"
                target="_blank"
                rel="nofollow"
              >
                United States Office of Foreign Assets Control
              </a>{" "}
              (OFAC). As a result, the AMZScout Affiliate program and AMZScout
              products are not available to anyone in the following countries or
              territories:
              <ol>
                <li>Crimea</li>
                <li>Cuba</li>
                <li>Iran</li>
                <li>North Korea</li>
                <li>Sudan</li>
                <li>Syria</li>
              </ol>
            </li>
            <li className="PgTOU-restrictions">
              <b>Recently added regions</b>
              <br />
              If you're based in a location that's recently become subject to
              OFAC sanctions, your AMZScout account will be suspended.
              <hr className="PgTOU__hr" />
              If your account is affected by OFAC sanctions, we'll notify you by
              email when we suspend your account. No grace periods or exceptions
              are possible.
              <hr className="PgTOU__hr" />
              If you believe your account shouldn't have been suspended, please
              contact our support staff to request an appeal.
            </li>
            <li className="PgTOU-restrictions">
              <b>Account access within embargoed regions</b>
              <br />
              Even if your account is not based in one of the embargoed
              countries or territories, you could still be affected by the
              embargo. That's because when you're physically present in an
              embargoed country or territory, you won't be able to sign in to
              AMZScout products.
            </li>
            <li>
              <b>Insurmountable Circumstances.</b> We shall not be liable for
              any failure of or delay in the performance of this Agreement for
              the period that such failure or delay is due to causes beyond our
              reasonable control, including but not limited to acts of God, war,
              hackers, third party internet providers, government orders, power
              failures, nuclear destruction, or any other insurmountable
              circumstance or event (<i>“Force Majeure”</i>).
            </li>
            <li>
              <b>Severability.</b> The invalidity or unenforceability of any
              provisions of this Agreement shall not affect the validity or
              enforceability of any other provision of this Agreement, which
              shall remain in full force and effect.
            </li>
            <li>
              <b>Survival.</b> Any provision of this Agreement which imposes an
              obligation after termination or expiration of this Agreement shall
              survive the termination or expiration of this Agreement.
            </li>
            <li>
              <b>Headings.</b> The section headings contained in this Agreement
              are for reference purposes only and shall not affect in any way
              the meaning or interpretation of this Agreement.
            </li>
            <li>
              <b>Waiver.</b> Our failure to exercise or delay in exercising any
              right, power or privilege under this Agreement shall not operate
              as a waiver; nor shall any single or partial exercise of any
              right, power or privilege preclude any other or further exercise
              thereof.
            </li>
            <li>
              <b>Amendment.</b> This Agreement will only be amended by our
              posting new terms on this Site. These new terms will then be
              incorporated into the existing Agreement. If there is a conflict
              between the existing terms and the new terms, the new terms shall
              control.
            </li>
            <li>{PG_TOU_INFO_SECTION_CONTENT[currentLocale]?.t3()}</li>
            <li>
              <b>Dispute Resolution.</b> In the event AMZScout and Member (the{" "}
              <i>“Parties”</i>) are not able to resolve any dispute between them
              arising out of or concerning these Terms, or any provisions
              hereof, whether in contract, tort, or otherwise at law or in
              equity for damages or any other relief, then such dispute shall be
              resolved only by final and binding arbitration pursuant to the
              Federal Arbitration Act, conducted by a single neutral arbitrator
              and administered by the American Arbitration Association (
              <i>“AAA”</i>) in the Commonwealth of Pennsylvania. The
              arbitrator's award shall be final, and judgment may be entered
              upon it in any court having jurisdiction. In the event that any
              legal or equitable action, proceeding or arbitration arises out of
              or concerns these Terms, the prevailing party shall be entitled to
              recover its costs and reasonable attorneys’ fees. The Parties
              agree to arbitrate all disputes and claims with respect to these
              Terms or any disputes arising as a result of these Terms, whether
              directly or indirectly, including tort claims that are a result of
              these Terms. The Parties agree that the Federal Arbitration Act
              governs the interpretation and enforcement of this provision. The
              entire dispute, including the scope and enforceability of this
              arbitration provision, shall be determined by the Arbitrator. This
              arbitration provision shall survive the termination of these Terms
              of Use.
              <ol>
                <li>
                  Arbitration shall proceed solely on an individual basis
                  without the right for any claims to be arbitrated on a class
                  action basis or on bases involving claims brought in a
                  purported representative capacity on behalf of others. The
                  arbitrator's authority to resolve and make written awards is
                  limited to claims between you and us alone. Claims may not be
                  joined or consolidated unless agreed to in writing by all
                  Parties. No arbitration award or decision will have any
                  preclusive effect as to issues or claims in any dispute with
                  anyone who is not a named party to the arbitration.
                </li>
                <li>
                  The above notwithstanding, if you violate these Terms then we
                  may seek injunctive or other equitable relief.
                </li>
              </ol>
            </li>
            <li>
              <b>Assignment.</b> You may not assign any of your rights under
              this Agreement to anyone else. We may assign our rights to any
              other individual or entity at our discretion.
            </li>
            <li>
              <b>Disclaimer.</b> In no event will the AMZScout Team be
              responsible for the actions of any third party. Third parties may
              include, but are not limited to, advertisers, linked websites or
              other members.
            </li>
            <li>
              <b>Notice.</b> All notices to you will be effective when we send
              them to the last email you gave us or posted on our Site. Any
              notice to us will be effective when delivered to us at:
              <ul>
                <li>
                  <b>Email:</b> support@amzsc.amzgb.com
                </li>
              </ul>
            </li>
            <li>
              <b>Copyright Infringement.</b> If copyrighted content that belongs
              to you was posted on the Site without your permission, please
              notify us at:
              <ol>
                <li>
                  <b>Email:</b> support@amzsc.amzgb.com
                </li>
                {LOCALIZATION_ENABLED && currentLocale === "us" && (
                  <li>{PG_TOU_INFO_SECTION_CONTENT[currentLocale]?.t2()}</li>
                )}
                <li>
                  Please include the following in your notice:
                  <ol className="with-mark">
                    <li>
                      An electronic or physical signature of the copyright owner
                      or someone authorized to act on their behalf;
                    </li>
                    <li>
                      The name, address, telephone number, and email address of
                      the copyright owner;
                    </li>
                    <li>
                      Identification of the copyrighted work that is being
                      infringed;
                    </li>
                    <li>
                      Identification of where the infringing material is located
                      on our Site (a URL works best);
                    </li>
                    <li>
                      A statement that you have a good faith belief that the use
                      isn’t authorized by the copyright owner, its agent or the
                      law;
                    </li>
                    <li>
                      A statement that the information in your notice is
                      accurate; and
                    </li>
                    <li>
                      A statement that you’re authorized to act to enforce the
                      copyright.
                    </li>
                  </ol>
                </li>
                <li>
                  This statement must be made under penalty of perjury, meaning
                  if any part of the statement is false, you could be committing
                  perjury which is a serious offense that is sometimes even
                  classified as a felony.
                </li>
                <li>
                  <b>Warning:</b> If you knowingly make a false statement in
                  your claim of copyright infringement, then you may be subject
                  to liability for damages and heavy civil penalties. If you’re
                  not sure whether material on one of our Sites infringes your
                  copyright, then you should speak with a lawyer before
                  notifying us. We may forward your notice to the user that
                  uploaded the content.
                </li>
              </ol>
            </li>
            <li>
              <b>Electronic Communications.</b> When you use the AMZScout
              service, or send emails, text messages, and other communications
              from your desktop or mobile device to us, you are communicating
              with us electronically. You consent to receive communications from
              us electronically. We will communicate with you in a variety of
              ways, such as by email, text, or by posting notices and messages
              on the Site. You agree that all agreements, notices, disclosures,
              and other communications that we provide to you electronically
              satisfy any legal requirement that such communications be in
              writing.
            </li>
            <li>
              <b>Entire Agreement.</b> This Agreement together with our Privacy
              Policy and any additional terms you have agreed to constitute(s)
              the entire agreement with respect to its subject matter and
              constitutes and supersedes all prior agreements, representations
              and understandings of the Parties, written or oral.
            </li>
          </ol>
        </div>
      </div>
    </div>
  );
};

export default InfoSection;
